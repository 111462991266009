import React from "react"

import Box from "../../components/box"
import Grid from "../../components/grid"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import Text from "../../components/text"

const FuehrungenPreise = () => {
  return (
    <Stack space={3}>
      <Grid columns={[1, 2]} space={1}>
        <Box p={3} bg="muted">
          <Stack space={1}>
            <Text color="whisper" sans size={2}>
              Dauer
            </Text>
            <Paragraph>60 Minuten</Paragraph>
          </Stack>
        </Box>
        <Box p={3} bg="muted">
          <Stack space={1}>
            <Text color="whisper" sans size={2}>
              Kosten
            </Text>
            <Paragraph>7 € / 5 € ermäßigt*</Paragraph>
          </Stack>
        </Box>
      </Grid>
      <Text>
        * Schüler*innen, Studierende oder Menschen mit Schwerbehinderten-Ausweis
      </Text>
    </Stack>
  )
}

export default FuehrungenPreise
