import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import FuehrungenPreise from "../../content/fuehrungen/preise"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import EventList from "../../components/event-list"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const Sonntagsfuehrungen = () => {
  const data = useStaticQuery(graphql`
    query {
      detail: file(relativePath: { eq: "ausstellungen/detail.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
      fuegungen: file(
        relativePath: { eq: "dauerausstellung/07-harte-fuegungen/cover.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="sonntagsfuehrungen">
      <Seo
        title="Sonntagsführungen"
        description="Jeden 1. und 3. Sonntag im Monat bieten wir um 14 Uhr eine 1-stündige öffentliche Führung durch die Dauerausstellung an."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Führungen",
              link: "/fuehrungen",
            },
            {
              title: "Sonntagsführungen",
              link: "/fuehrungen/sonntagsfuehrungen",
            },
          ]}
        />
        <PageTitle>Sonntagsführungen</PageTitle>

        <Constrain>
          <Paragraph dropcap={true}>
            Jeden 1. und 3. Sonntag im Monat bieten wir eine öffentliche Führung
            durch die{" "}
            <TextLink to="/ausstellungen/dauerausstellung">
              Dauerausstellung
            </TextLink>{" "}
            an. Der 60-minütige Rundgang führt durch alle Räume der Ausstellung
            und vermittelt anhand überlieferter Manuskripte, Dokumente und
            Besucherberichte Hölderlins Lebensalltag und poetisches Schaffen
            während seiner letzten 36 Jahre im Turm.
          </Paragraph>
        </Constrain>
        <Grid columns={2} space={"px"}>
          <Image
            image={data.detail.childImageSharp.gatsbyImageData}
            alt="Bild des Raumes Tübinger Hoffnungen im ersten Stock des Turms. An der Wand eine graue Vitrine mit historischer Stadtansicht"
            attribution="David Franck"
          />
          <Image
            image={data.fuegungen.childImageSharp.gatsbyImageData}
            alt="Raumansicht mit neun dunklen säulenartigen Vitrinen an der Wand und einer Vitrine mit Glashaube in der Mitte"
            attribution="David Franck"
          />
        </Grid>

        <Constrain>
          <Stack>
            <Paragraph>
              Auch Hölderlins Studienjahre am Evangelischen Stift, die dort
              geknüpften Freundschaften und ersten Gedichtveröffentlichungen
              sowie die außerhalb Tübingens entstandenen Werke werden an
              anschaulichen Zeugnissen thematisiert. Darüber hinaus verfolgt der
              Rundgang Hölderlins große Wirkung auf die Literatur, Musik und
              Kunst und spürt den stilistischen Eigenheiten seiner Gedichte
              nach.
            </Paragraph>
            <Stack space={6}>
              <Heading as="h2" level={5}>
                Dauer & Kosten
              </Heading>
              <FuehrungenPreise />
            </Stack>
            <Stack space={6}>
              <Heading as="h2" level={5}>
                Anmeldung
              </Heading>
              <Paragraph>
                Die Anmeldung erfolgt an der Museumskasse. Eine telefonische
                Voranmeldung ist während der{" "}
                <TextLink to="/kontakt#oeffnungszeiten">
                  Museumsöffnungszeiten
                </TextLink>{" "}
                unter 07071 2041862 möglich.
              </Paragraph>
            </Stack>
          </Stack>
        </Constrain>

        <Stack space={6}>
          <Constrain>
            <Heading as="h2" level={5}>
              Nächste Termine
            </Heading>
          </Constrain>
          <EventList
            density="compact"
            showPastEvents={true}
            category="fuehrung-dauerausstellung"
          />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Sonntagsfuehrungen
